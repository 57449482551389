import { ITableFilter, OrderStageType, SalaryType } from "@lib";
import { Period } from "@/core/types";
import { Select, CheckBox, Control, DateRange, MultiSelect, TextBox } from "@/@core/components/alt-ui/controls";
import { Footer } from "@/@core/controls/footer.control";
import { FilterController } from "@/@core/filters/filter-controller";
import { FilterType, FilterPair } from "@/utils/filter";
import { ISelectOption } from "@/@core/types/common/select-options";
import { PeriodUtils } from "@/utils/types/period.utils";

export type SalaryFilter = Partial<{
    showPaid: boolean;
    type: string[];
    stage: string[];
    period: string;
    dates: FilterPair;
}>;

export interface ISalaryFilterContext {
    settings?: {
        worksPercent: number;
        materialsPercent: number;
        salesPercent: number;
    };
    defaultFilter: SalaryFilter;
    currentFilter: ITableFilter[];
}

export const SalaryTypeOptions: ISelectOption[] = [
    {
        id: SalaryType.Orders,
        name: "Заявки",
    },
    {
        id: SalaryType.Works,
        name: "Работы",
    },
    {
        id: SalaryType.Materials,
        name: "Материалы",
    },
    {
        id: SalaryType.Sales,
        name: "Продажи",
    },
];

export const SalaryOrderStageOptions: ISelectOption[] = [
    { id: "closed", name: "Закрыты" },
    { id: "ready", name: "Готовы" },
];

export class SalaryFilterController extends FilterController<ISalaryFilterContext, void> {
    public id: string = "salary-filter";
    public title: string = "Фильтр";

    public additionalControls = {
        settingsWorksPercent: new TextBox(),
        settingsMaterialsPercent: new TextBox(),
        settingsSalesPercent: new TextBox(),
    };

    public filter = {
        showPaid: {
            control: new CheckBox(),

            type: FilterType.Equals,

            get: (): boolean => {
                return this.filter.showPaid.control.value;
            },
            set: (v: boolean | "true" | "false"): void => {
                if (v === "true") {
                    this.filter.showPaid.control.value = true;
                } else if (v === "false") {
                    this.filter.showPaid.control.value = false;
                } else {
                    this.filter.showPaid.control.value = v;
                }
            },
        },
        type: {
            control: new MultiSelect<ISelectOption>(),

            type: FilterType.In,

            get: (): string[] => {
                return this.filter.type.control.selectedItems.map(e => e.id);
            },
            set: (ids: string[]): void => {
                this.filter.type.control.selectedItems = this.findItemsByIds(SalaryTypeOptions, ids, "id");
            },
        },
        stage: {
            control: new MultiSelect<ISelectOption>(),

            type: FilterType.In,

            get: (): string[] => {
                return this.filter.stage.control.selectedItems.map(e => e.id);
            },
            set: (ids: string[]): void => {
                this.filter.stage.control.selectedItems = this.findItemsByIds(SalaryOrderStageOptions, ids, "id");
            },
        },
        period: {
            control: new Select<ISelectOption<Period>>(),

            type: FilterType.Equals,

            get: (): string => {
                return this.filter.period.control.selectedItem?.id ?? Period.Custom;
            },
            set: (id: string): void => {
                const periods = PeriodUtils.getOptions();
                const period = periods.find(e => e.id === id) as ISelectOption<Period>;
                const customPeriod = periods.find(e => e.id === Period.Custom) as ISelectOption<Period>;

                this.filter.period.control.selectedItem = period ?? customPeriod;
            },
        },
        dates: {
            control: new DateRange(),

            type: FilterType.Between,

            get: (): FilterPair => {
                return this.filter.dates.control.value;
            },
            set: (date: FilterPair): void => {
                if (this.filter.period.get() !== Period.Custom) {
                    return;
                }

                this.filter.dates.control.value = date;
            },
        },
    };

    private ftFooter!: Footer;

    public context!: ISalaryFilterContext;

    public onSave: ((filter: ITableFilter[]) => Promise<boolean>) | null = null;

    constructor() {
        super();
    }

    public init(context: ISalaryFilterContext): void {
        this.context = context;
        this.initControls();
        this.initFooter();
        this.populateFilters();
    }

    public show(context: ISalaryFilterContext): Promise<void> {
        this.context = context;
        this.initControls();
        this.initFooter();
        this.populateFilters();

        return super.show(context);
    }

    get selectOnlyPaid(): boolean {
        return this.filter.showPaid.control.value;
    }

    public setCurrentFilter(filter: ITableFilter[]): void {
        this.context.currentFilter = filter;

        this.populateFilters();
    }

    // Init

    private initControls(): void {
        this.filter.type.control = new MultiSelect<ISelectOption>();
        this.filter.type.control.id = this.getControlId("type");
        this.filter.type.control.label = "Выплаты за";
        this.filter.type.control.items = SalaryTypeOptions;
        this.filter.type.control.textField = e => e.name;

        this.filter.stage.control = new MultiSelect<ISelectOption>();
        this.filter.stage.control.id = this.getControlId("stage");
        this.filter.stage.control.label = "Заявки на этапе";
        this.filter.stage.control.items = SalaryOrderStageOptions;
        this.filter.stage.control.textField = e => e.name;

        this.filter.period.control = new Select<ISelectOption<Period>>();
        this.filter.period.control.id = this.getControlId("period");
        this.filter.period.control.label = "Период";
        this.filter.period.control.items = PeriodUtils.getOptions();
        this.filter.period.control.textField = e => e.name;
        this.filter.period.control.addChangedHandler((_, event) => {
            const period = event.item;
            if (!period) {
                return;
            }

            this.filter.dates.control.disabled = period.id !== Period.Custom;

            const dates = PeriodUtils.getDates(period.id);
            if (dates) {
                this.filter.dates.control.value = dates;
            }
        });

        this.filter.dates.control = new DateRange();
        this.filter.dates.control.id = this.getControlId("dates");
        this.filter.dates.control.label = "Даты";

        this.filter.showPaid.control.id = this.getControlId("showPaid");
        this.filter.showPaid.control.text = "Показать только выплаченное";

        const additionals = this.additionalControls;

        additionals.settingsWorksPercent.id = this.getControlId("settingsWorksPercent");
        additionals.settingsWorksPercent.label = "Процент за работы";
        additionals.settingsWorksPercent.validation = "required|numeric|unsigned";
        additionals.settingsWorksPercent.text = "40";

        additionals.settingsMaterialsPercent.id = this.getControlId("settingsMaterialsPercent");
        additionals.settingsMaterialsPercent.label = "Процент за материалы";
        additionals.settingsMaterialsPercent.validation = "required|numeric|unsigned";
        additionals.settingsMaterialsPercent.text = "40";

        additionals.settingsSalesPercent.id = this.getControlId("settingsSalesPercent");
        additionals.settingsSalesPercent.label = "Процент за продажи";
        additionals.settingsSalesPercent.validation = "required|numeric|unsigned";
        additionals.settingsSalesPercent.text = "40";
    }

    private initFooter(): void {
        this.ftFooter = new Footer({
            okText: "Сохранить",
            okHandler: this.clickSave.bind(this),
            cancelHandler: this.clickCancel.bind(this),
        });
    }

    private populateFilters(): void {
        if (this.context.settings) {
            const settings = this.context.settings;

            this.additionalControls.settingsWorksPercent.text = settings.worksPercent.toString() ?? "40";
            this.additionalControls.settingsMaterialsPercent.text = settings.materialsPercent.toString() ?? "40";
            this.additionalControls.settingsSalesPercent.text = settings.salesPercent.toString() ?? "40";
        }

        if (this.context.defaultFilter) {
            Object.entries(this.context.defaultFilter).forEach(([k, filter]) => {
                const key = k as keyof SalaryFilter;

                if (!this.filter[key]) {
                    return;
                }

                // @ts-ignore
                this.filter[key].set(filter as any);
            });
        }

        if (!this.context.currentFilter) {
            return;
        }

        const filters = this.getLocalFilter(this.context.currentFilter);

        Object.entries(filters).forEach(([k, filter]) => {
            const key = k as keyof SalaryFilter;

            if (!this.filter[key]) {
                return;
            }

            // Обратная совместимость с старой версией фильтров
            if (key === "type" && !isNaN(Number(filter))) {
                this.filter[key].set([SalaryType.Orders, SalaryType.Works, SalaryType.Materials]);
                return;
            }

            // @ts-ignore
            this.filter[key].set(filter as any);
        });
    }

    public get controls(): Control[] {
        if (this.selectOnlyPaid) {
            return [this.filter.showPaid.control, this.filter.period.control, this.filter.dates.control];
        }

        return [
            this.filter.showPaid.control,
            this.filter.type.control,
            this.filter.stage.control,
            this.filter.period.control,
            this.filter.dates.control,
            this.additionalControls.settingsWorksPercent,
            this.additionalControls.settingsMaterialsPercent,
            this.additionalControls.settingsSalesPercent,
        ];
    }

    public get footer(): Control {
        return this.ftFooter;
    }

    // /Init

    public get tableFilter(): ITableFilter[] {
        return this.getTableFilter(this.filter);
    }

    public get employeeSettings(): {
        works: string;
        materials: string;
        sales: string;
    } {
        return {
            works: this.additionalControls.settingsWorksPercent.text,
            materials: this.additionalControls.settingsMaterialsPercent.text,
            sales: this.additionalControls.settingsSalesPercent.text,
        };
    }

    public async clickSave(): Promise<void> {
        const valid = await this.validate();
        if (!valid) {
            return;
        }

        if (!this.onSave) {
            return;
        }

        this.hide();

        await this.onSave(this.tableFilter);
    }

    public clickCancel(): void {
        this.hide();
    }
}
