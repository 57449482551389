import { ISalary, ISalaryReserveDto } from "@lib";
import { Modal } from "@core/components/alt-ui/modal";
import { Button, Control, Panel, TextArea, TextBox } from "@core/components/alt-ui/controls";

export enum SalaryAddType {
    Bonus = "bonus",
    Fine = "fine",
}

export class SalaryAddModal extends Modal<SalaryAddType> {
    private tbAmount!: TextBox;
    private taComment!: TextArea;

    private pnlFooter!: Panel;
    private btnCancel!: Button;
    private btnSave!: Button;

    private type!: SalaryAddType;

    public onCreate: ((type: SalaryAddType, dto: ISalaryReserveDto) => Promise<ISalary | null>) | null = null;

    public constructor() {
        super("salary-add-modal", "Новая выплата");
        this.initializeControls();
    }

    public show(type: SalaryAddType): Promise<void> {
        this.type = type;
        this.title = type === SalaryAddType.Bonus ? "Новая премия" : "Новый штраф";
        this.initializeControls();
        return super.show();
    }

    private initializeControls(): void {
        this.tbAmount = new TextBox();
        this.tbAmount.id = "salary-add.amount";
        this.tbAmount.label = "Сумма";
        this.tbAmount.validation = "required|money|unsigned";

        this.taComment = new TextArea();
        this.taComment.id = "salary-add.comment";
        this.taComment.label = "Комментарий";
        this.taComment.validation = "required";

        //

        this.btnCancel = new Button();
        this.btnCancel.id = "salary-add.cancel";
        this.btnCancel.text = "Отменить";
        this.btnCancel.class = "mr-0.75";
        this.btnCancel.variant = "outline-danger";
        this.btnCancel.addClickHandler(this.clickCancel.bind(this));

        this.btnSave = new Button();
        this.btnSave.id = "salary-add.save";
        this.btnSave.text = "Добавить";
        this.btnSave.addClickHandler(this.clickSave.bind(this));

        this.pnlFooter = new Panel();
        this.pnlFooter.class = "flex justify-end mt-2";
        this.pnlFooter.addControls([this.btnCancel, this.btnSave]);
    }

    public get controls(): Control[] {
        return [this.tbAmount, this.taComment, this.pnlFooter];
    }

    private async clickCancel(sender: any, e: any): Promise<void> {
        this.hide();
    }

    private async clickSave(sender: any, e: any): Promise<void> {
        const valid = await this.validate();
        if (!valid) {
            return;
        }

        if (!this.onCreate) {
            return;
        }

        const factor = this.type === SalaryAddType.Fine ? -1.0 : 1.0;
        const amountStr = this.tbAmount.text.replace(",", ".");
        const amount = parseFloat(amountStr) * factor;

        const dto: ISalaryReserveDto = {
            amount: amount,
            comment: this.taComment.text,
        };

        const result = await this.onCreate(this.type, dto);

        if (result) {
            this.hide(result);
        }
    }
}
