import { ISalary, ISalaryPaymentDto, ISalaryUseCase } from "@lib";
import { ISalaryProcessor } from "./salary-processor";
import { ISalaryTableItem } from "../salary-table";

export class SalaryReservedProcessor implements ISalaryProcessor {
    public async pay(
        companyId: string,
        accountId: string,
        item: ISalaryTableItem,
        usecase: ISalaryUseCase,
    ): Promise<ISalary> {
        const employeeId = item.meta.employee;
        const salaryId = item.meta.salary?.id as string;
        const description = "Выплата сотруднику";

        const paymentDto: ISalaryPaymentDto = {
            account: accountId,
            description: description,
        };

        return await usecase.pay(companyId, employeeId, salaryId, paymentDto);
    }
}
