import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import { BDropdown, BDropdownItem, VBTooltip } from "bootstrap-vue";
import { IEmployee, PermissionType, PermissionRight } from "@lib";
import { Period } from "@/core/types";
import AltAutoButton from "@/core/components/alt-auto-button";
import { ISelectOption } from "@core/types/common/select-options";
import { datetime } from "@/filters";
import { SalaryFilterController } from "../salary-filter-controller";
import { PeriodUtils } from "@/utils/types/period.utils";

@Component({
    name: "salary-toolbar",
    components: { BDropdown, BDropdownItem, AltAutoButton },
    directives: { "b-tooltip": VBTooltip },
})
export default class SalaryToolbar extends Vue {
    @Prop({ type: Array, required: true })
    private employees!: IEmployee[];

    @Prop({ default: () => null })
    private employeeSelected!: IEmployee | null;

    @Prop({ type: Array, default: () => [] })
    private salariesUnpaid!: any[];

    @Prop({ type: Object, required: true })
    private filterController!: SalaryFilterController;

    private employee = this.getSelectedEmployee();

    private get can(): any {
        const employeeId = (this.employee?.user as string) ?? "";
        const secure = this.$secure;
        return {
            get read(): boolean {
                return secure.check(PermissionType.Employees, employeeId, PermissionRight.SalaryRead);
            },
            get create(): boolean {
                return secure.check(PermissionType.Employees, employeeId, PermissionRight.SalaryCreate);
            },
        };
    }

    private get employeesAvailable(): IEmployee[] {
        return this.employees;

        // return this.employees.filter(
        //     e => this.$secure.check(PermissionType.Employees, e.id, PermissionRight.SalaryRead)
        // );
    }

    private get periods(): ISelectOption<Period>[] {
        return PeriodUtils.getOptions();
    }

    private get periodSelectedText(): string {
        const periodId = this.filterController.filter.period.get();
        const period = this.periods.find(e => e.id === periodId);
        const dates = this.filterController.filter.dates.get();

        if (!period || period.id === Period.Custom) {
            const from = datetime.datetime(dates[0], "L");
            const to = datetime.datetime(dates[1], "L");
            return from === to ? from : `${from} — ${to}`;
        }

        return period ? period.name : this.periods[this.periods.length - 1].name;
    }

    @Watch("employeeSelected")
    private onEmployeeChanged(): void {
        this.employee = this.getSelectedEmployee();
    }

    private getSelectedEmployee(): IEmployee | null {
        return this.employeeSelected;

        // const emp = this.employeesAvailable.find(e => e.id === this.employeeSelected?.id);
        // if (emp)
        //     return emp;

        // if (this.employeesAvailable.length > 0)
        //     return this.employeesAvailable[0];

        // return null;
    }

    private showPayAllModal(): void {
        this.$emit("show-payall-modal");
    }

    private async changePeriod(period: ISelectOption): Promise<void> {
        this.$emit("change-period", period);
    }

    private showFilter(): void {
        this.$emit("show-filter");
    }

    private addBonus(): void {
        this.$emit("add-bonus");
    }

    private addFine(): void {
        this.$emit("add-fine");
    }
}
