import { datetime } from "@/filters/datetime";
import { IAccount, IOrder, ISalary, ISalarySubject, ISale, ITransaction, Locale } from "@lib";
import { ISalaryTableItem, SalaryTableItemType } from ".";

export default class SalaryTablePaidConverter {
    private salaries: ISalary[];
    private userId: string;
    private accounts: IAccount[];

    public constructor(salaries: ISalary[], userId: string, accounts: IAccount[]) {
        this.salaries = salaries;
        this.userId = userId;
        this.accounts = accounts;
    }

    public convert(): ISalaryTableItem[] {
        const items: ISalaryTableItem[] = [];
        for (const salary of this.salaries) {
            const item = this.salaryToItem(salary);
            items.push(item);

            for (const subject of salary.subject) {
                const itemSubj = this.subjectToItem(subject, item);
                items.push(itemSubj);
            }
        }
        return items;
    }

    private salaryToItem(salary: ISalary): ISalaryTableItem {
        const item: ISalaryTableItem = {
            type: SalaryTableItemType.Paid,
            number: salary.number,
            name: this.getSalaryName(salary),
            amount: salary.amount,
            meta: {
                salary,
                employee: this.userId,
                //salary: salaryLink,
                locale: Locale.RU, // salary.shopRef?.info.locale,
            },
            paid: true,
        };

        if (salary.transactionRef) {
            const transaction = salary.transactionRef;
            item.transaction = transaction;

            const account = this.accounts.find(account => account.id === transaction.account);
            if (account) {
                item.account = account.info.name;
            }
        }

        return item;
    }

    private getSalaryName(salary: ISalary): string {
        const date = datetime((salary as any).updatedAt);
        return `Выплата от ${date}`;
        // let name = "";
        // for (const subject of salary.subject) {
        //     if (subject.order) {
        //         if (name.length > 0) name += ",\n";
        //         name += `Заявка №${ (subject.order.ref as IOrder).number }`;
        //     }
        //     if (subject.sale) {
        //         if (name.length > 0) name += ",\n";
        //         name += `Продажа №${ (subject.sale.ref as ISale).number }`;
        //     }
        //     if (subject.bonus) {
        //         name += subject.bonus.comment;
        //     }
        //     if (subject.fine) {
        //         name += subject.fine.comment;
        //     }
        // }
        // return name.length > 0 ? name : "Выплата";
    }

    private subjectToItem(subject: ISalarySubject, parent: ISalaryTableItem): ISalaryTableItem {
        const type = SalaryTableItemType.Paid;
        let name = "Выплата";
        let amount = 0;

        if (subject.order) {
            //type = SalaryTableItemType.Order;
            name = `Заявка №${(subject.order.ref as IOrder).number}`;
            amount = subject.order.amount;
        }
        if (subject.sale) {
            //type = SalaryTableItemType.Sale;
            name = `Продажа №${(subject.sale.ref as ISale).number}`;
            amount = subject.sale.amount;
        }
        if (subject.bonus) {
            //type = SalaryTableItemType.Reserved;
            name = subject.bonus.comment ?? "Премия";
            amount = subject.bonus.amount;
        }
        if (subject.fine) {
            //type = SalaryTableItemType.Reserved;
            name = subject.fine.comment ?? "Штраф";
            amount = subject.fine.amount;
        }

        return {
            type: type,
            name: name,
            amount: amount,
            meta: {
                employee: this.userId,
                //salary: salaryLink,
                locale: Locale.RU, // salary.shopRef?.info.locale,
            },
            parent: parent,
            paid: true,
        };
    }

    public static convert(salary: ISalary[], userId: string, accounts: IAccount[]): ISalaryTableItem[] {
        return new SalaryTablePaidConverter(salary, userId, accounts).convert();
    }
}
